/** Liturgy calendar */

.ap-teaser.ap-service {

    .ap.ap-list-group {
        margin-bottom: $default-margin-small;
    }

    & [class~='ap'][class~='ap-list-group']:last-of-type {
        // list groups need a margin between elements, but never at the last element
        margin-bottom: 0;
    }

    .ser-nocalendar {
        .calendar-simple {
            font-size: $h3-size;
            line-height: $h3-height;
            margin-bottom: 0.5em;
        }
    }

    .ser-btn {
        margin-top: $default-margin-verysmall;
    }

    @media (min-width: $screen-sm-min) {
        .ser-head {
            font-size: $h4-size;
            line-height: $h4-height;
        }
        .ser-nocalendar {
            .calendar-simple {
                font-size: $h2-size;
                line-height: $h2-height;
            }
        }
    }
}

.ap-detail-page.ap-service {

    .ser-head {
        margin-bottom: 10px;
    }

    .ser-lead {
        @include text-lead;
    }
}
