/* prompt-300 - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 300;
  src: local('Prompt Light'), local('Prompt-Light'),
       url('../fonts/prompt-v1-latin-ext_latin-300.woff2') format('woff2'),
       url('../fonts/prompt-v1-latin-ext_latin-300.woff') format('woff');
}
/* prompt-300italic - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 300;
  src: local('Prompt Light Italic'), local('Prompt-LightItalic'),
       url('../fonts/prompt-v1-latin-ext_latin-300italic.woff2') format('woff2'),
       url('../fonts/prompt-v1-latin-ext_latin-300italic.woff') format('woff');
}
/* prompt-regular - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  src: local('Prompt'), local('Prompt-Regular'),
       url('../fonts/prompt-v1-latin-ext_latin-regular.woff2') format('woff2'),
       url('../fonts/prompt-v1-latin-ext_latin-regular.woff') format('woff');
}
/* prompt-italic - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 400;
  src: local('Prompt Italic'), local('Prompt-Italic'),
       url('../fonts/prompt-v1-latin-ext_latin-italic.woff2') format('woff2'),
       url('../fonts/prompt-v1-latin-ext_latin-italic.woff') format('woff');
}

/* prompt-700 - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 700;
  src: local('Prompt Bold'), local('Prompt-Bold'),
       url('../fonts/prompt-v1-latin-ext_latin-700.woff2') format('woff2'),
       url('../fonts/prompt-v1-latin-ext_latin-700.woff') format('woff');
}
/* prompt-700italic - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 700;
  src: local('Prompt Bold Italic'), local('Prompt-BoldItalic'),
       url('../fonts/prompt-v1-latin-ext_latin-700italic.woff2') format('woff2'),
       url('../fonts/prompt-v1-latin-ext_latin-700italic.woff') format('woff');
}