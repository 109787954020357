/**********
 * Source file: quiz.scss
 */

.type-quiz {

    &.ap-detail-page {
        margin-bottom: $default-margin;
    }

    .quiz-start {
        // additional padding that is taken into account when height is calculated in JS
        padding-bottom: $default-margin;
    }

    .quiz-start,
    .quiz-content {
        display: none;
        &.active {
            display: block;
        }
    }

    .answers li,
    .counter {
        margin-bottom: $default-margin-small;
    }

    .btn-quiz {
        font-weight: bold;
    }

    .next-item {
        margin-top: $default-margin;
        font-size: 120%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > .btn-quiz {
            display: inline;

            &::after {
                display: inline-block;
                font-family: FontAwesome;
                content: "\f138";
                margin-left: $default-margin-verysmall;
                color: $main-theme;
                text-decoration: none;
            }
        }
    }

    .animation {
        &.enter {
            animation: fadeIn 1s;
            &.slow {
                animation: fadeIn 2s;
            }
        }
        &.leave {
            animation: fadeOut 0.5s;
        }
        &.quick {
            animation: none;
        }
    }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

