/** Day Readings */

.ap-dayreadings {

    .readingdate {
        margin-bottom: 8px;
    }

    .pericopes {
        margin-top: 12px;
        border-top: 1px solid $grey-lighter;
        display: inline-block;
        width: 100%;

        .title {
            margin-top: 12px;

            .type {
                font-weight: bold;
            }
        }

        .motto {
            font-style: italic;
            margin-top: 8px;
        }
    }

    .verse {
        padding-top: 8px;
        clear: both;

        .number {
            float: left;
            display: inline-block;
            width: 10%;
            font-weight: bold;
        }

        .text {
            float: right;
            display: inline-block;
            width: 90%;
        }
    }

    .verselast div {
        padding-bottom: 0;
    }
}
