/* open-sans-300 - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('../fonts/open-sans-v13-latin_latin-ext-300.woff2') format('woff2'),
       url('../fonts/open-sans-v13-latin_latin-ext-300.woff') format('woff');
}
/* open-sans-300italic - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'),
       url('../fonts/open-sans-v13-latin_latin-ext-300italic.woff2') format('woff2'),
       url('../fonts/open-sans-v13-latin_latin-ext-300italic.woff') format('woff');
}
/* open-sans-regular - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'),
       url('../fonts/open-sans-v13-latin_latin-ext-regular.woff2') format('woff2'),
       url('../fonts/open-sans-v13-latin_latin-ext-regular.woff') format('woff');
}
/* open-sans-italic - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
       url('../fonts/open-sans-v13-latin_latin-ext-italic.woff2') format('woff2'),
       url('../fonts/open-sans-v13-latin_latin-ext-italic.woff') format('woff');
}
/* open-sans-700 - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/open-sans-v13-latin_latin-ext-700.woff2') format('woff2'),
       url('../fonts/open-sans-v13-latin_latin-ext-700.woff') format('woff');
}
/* open-sans-700italic - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
       url('../fonts/open-sans-v13-latin_latin-ext-700italic.woff2') format('woff2'),
       url('../fonts/open-sans-v13-latin_latin-ext-700italic.woff') format('woff');
}