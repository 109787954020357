/**********
 * Theme file: all-variables.scss
 *
 * ALL Bistuemers - Common CSS classes.
 */



.h1, .h2, .h3, .h4,
h1, h2, h3, h4,
.ap-teaser p.teaser-headline > {
    a {
        &,
        &:active {
            color:      $text-dark;
            outline:    0 !important;
        }
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }
}

.ap-linksequence-hf {

    ul.links {

        li > a {
            font-size: 14px;
            color: $nav-sitename-color;
        }
    }
}

.ap-header {

    .nav-sidelogo {
        // side logo is displayed above right-side mobile navigation
        margin: 5px 2 * $gutter 5px $gutter;

        > .sidelogo {
            display: inline-block;
                .img-responsive {
                    max-width: $nav-mobile-side-size;
                }
        }
    }

    // special right positioning of the sitename
    .bis-site {

        @media (min-width: $screen-desktop-min) {
            float: none;
            position: absolute;
            right: 0;
            bottom: -20px;
        }

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            // make sure the site name is below the mobile navigation button
            padding-top: 60px;
        }

        .sitename {
            float: right;
            h2 {
                @extend .h1;
                color: $nav-sitename-color;
                letter-spacing: 0.06em;
            }
        }
    }

    // add padding below logo group, so that the sitename can be placed "below"
    .bis-logogroup {
        position: relative;
        margin-bottom: $default-margin;
    }

    @media (max-width: $screen-xs-max) {
        .head.notfixed .bis-logogroup .bis-logo .ap-section.image-only {
            margin-bottom: $default-margin;
        }
    }

    @media (max-width: $screen-mobile-max) {

        .bis-logogroup {
            // remove padding from logogroup since the sitename will now be in a new line
            margin-bottom: 0;
        }

        // re-position nav toggle on top
        .head-toggle {
            bottom: auto;
            top: 40px;
        }

        .head-navbar {
            border-bottom: none;

            @media (min-width: $screen-desktop-min) {
                // add back last grey line to mobile menu (this is removed in default CSS)
                .nav > li:nth-last-child(2) > a {
                    border-bottom: solid 1px $nav-border;
                }
            }
        }
    }

    &.bis-header-fullimage {
        // offset of sitename here
        .bis-logogroup {
            margin-bottom: 0;
        }

        .bis-site {
            bottom: 0;
        }
    }

    &.bis-header-schmuckband {

        // make the "schmuckband" in the demo header appear in theme colors
        .bis-schmuckband {
            margin-bottom: 0;

            .image-outer-box {
                background-color: $main-theme;
                border-radius: 0;

                .image-src-box {
                    // prevent "Schmuckband" from being to high
                    padding-bottom: initial !important;
                    max-height: 50px;
                    min-height: 50px;

                    .image-inner-box {
                        opacity: 0.2;
                        border-radius: 0;
                        left: initial;
                        right: 0;
                        height: 50px;
                        width: 691px;
                        min-width: 691px;
                    }

                    @media (min-width: $screen-sm-min) {
                        max-height: 60px;
                        min-height: 60px;
                        width: $container-sm;
                        margin-left: auto;
                        margin-right: auto;

                        .image-inner-box {
                            height: 60px;
                            width: 737px;
                            min-width: 737px;
                        }
                    }

                    @media (min-width: $screen-md-min) {
                        max-height: 80px;
                        min-height: 80px;
                        width: $container-md;

                        .image-inner-box {
                            height: 80px;
                            width: 1106px;
                            min-width: 1106px;
                        }
                    }
                    @media (min-width: $screen-lg-min) {
                        width: $container-lg;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }

        .sticky .isfixed { // NOTE: .isfixed implies desktop size, so no additional media queries required
            // remove some margins from header if fixed
            .bis-site {
                bottom: 0;
            }
            .bis-logogroup {
                margin-bottom: 0;
            }
        }

        @media (max-width: $screen-mobile-max) {
            // remove space between the "schmuckband" and the breadcrump bar
            .head-container {
                padding-bottom: 0;
            }
        }
    }

    // reduce default Boostrap breadcrump padding
    .breadcrumbs {
        ul.breadcrumb {
            padding: 14px 0;
        }
    }
}


.isfixed .head-visual .head-container {
    padding-top: 0;
    padding-bottom: 0;

    .head-visual-container .head-visual-group {
        margin-bottom: 0;
    }
    .head-visual-right {
        bottom: 0;
    }
    .head-visual-row.bis-top,
    .head-visual-row.bis-bottom {
        display: none;
    }
}


.area-foot {

    .headline {
        border-bottom: none;
        margin-bottom: 7px;
    }

    @if ($headlines-are-underlined) {
        .headline {
            h1,
            h2,
            h3,
            h4 {
                display: block;
                border-bottom: 2px solid $main-theme;
            }
        }
    }
}


// Adjustmensts for social icons in header
@media (max-width: $screen-mobile-max) {
    // Adjustmensts for social icons in header
    .ap-nav-header > .ap-social {
        float: right;
        margin-right: 30px;
        margin: #{$social-links-margin} 30px 0 0;
    }
}


// Special ReadSpeaker adjustments
#apollo-page .ap.type-rs {
    // #apollo-page id selector required to make rule stronger and overwritie the default ReadSpeaker CSS
    > .btn-wrapper {
        min-height: 30px;
        > .rsbtn {
            line-height: 1;
            padding-bottom: 0 !important;
        }

        .rsbtn_play:not([data-rsevent-id]) {
            color: transparent;
        }
    }

    &.btn-right,
    &.btn-center {
        display: flex;
        > .btn-wrapper {
            // float: right;
            .rsbtn.rsexpanded {
                .rsbtn_exp {
                    display: inline-block;
                }
            }
        }
    }

    &.btn-right {
        justify-content: flex-end;
    }

    &.btn-center {
        justify-content: center;
    }

    &.presized > .btn-wrapper {
        max-height: 30px;
    }

    .external-cookie-notice {
        padding: 2px 5px;
        justify-content: flex-start;
    }
}


// Special walls.io adjustments
.ap.type-wallsio {

    &:not(.external-cookie-notice) {

        &.remove-margin {
            .wallsio-container {
                // walls.io adds a margin of 12 around iframe body and padding of 9 to elements
                // result is a 21px additional margin
                margin-left: -21px;
                margin-right: -21px;

                .wallsio-load-more-button {
                    margin-left: 21px;
                }
            }
        }

        .wallsio-load-more-button {
            @extend .btn;
        }
    }
}