/** Liturgy calendar */

.ap-liturgy {

    .calendar-simple {
        font-size: $h3-size;
        line-height: $h3-height;
        margin-bottom: 0.5em;
    }

    @media (min-width: $screen-sm-min) {
        .calendar-simple {
            font-size: $h2-size;
            line-height: $h2-height;
        }
    }
}

.direktorium {

    .dday {
        position: relative;

        &:last-of-type,
        &.lestrue {
            margin-bottom: $default-margin-small;
        }
    }


    // wichtige Feste oder Ereignisse
    .grad1,
    .grad2,
    .grad3,
    .grad4,
    .grad5 {
        .dheader {
            font-weight: bold;
        }
    }

    // i.d.R. Bezeichnungen für Tage
    .grad6,
    .grad9,
    .grad13 {
        // font-style: italic;
    }

    // i.d.R. Namen von Personen
    .grad7,
    .grad8,
    .grad10,
    .grad11,
    .grad12 {
        // color: lighten($text-content, 25%);
    }

    .farbew,
    .farber,
    .farbev,
    .farbeg,
    .farbet {
        padding-left: 1.5rem;

        &::before {
            content: "";
            display: block;
            border: 1px solid lighten($text-content, 25%);
            width: 1rem;
            height: 1rem;
            border-radius: 100%;
            position: absolute;
            left: 0;
            top: 0.6rem;
        }
    }

    .farbew::before {
        background-color: #fff;
    }
    .farber::before {
        background-color: #faa;
    }
    .farbev::before {
        background-color: #dad;
    }
    .farbeg::before {
        background-color: #afa;
    }
    .farbet::before {
        border-color: transparent;
        background-color: inherit;
    }

    // Hochfeste und Feste
    .rangF {
        .dheader {
            @extend .h4;
            color: $text-content;
            font-weight: normal;
            margin-bottom: 0;
        }
        &.farbew,
        &.farber,
        &.farbev,
        &.farbeg,
        &.farbet {
            &::before {
                margin-bottom: 0.1rem;
            }
        }
    }
    .rangH {
        .dheader {
            @extend .h3;
            color: $text-content;
            font-weight: bold;
            margin-bottom: 0;
        }
        &.farbew,
        &.farber,
        &.farbev,
        &.farbeg,
        &.farbet {
            &::before {
                margin-bottom: 0.2rem;
            }
        }
    }

    .dheader {
        margin-bottom: 0;
    }

    .dlesung {
        margin-top: $default-margin-verysmall;
        font-size: #{$font-content-size * 0.9};
        line-height: #{$line-height-content * 0.9};
        color: lighten($text-content, 25%);

        .dl1,
        .dl2,
        .dap,
        .dev {
            &::before {
                display: inline-block;
                min-width: 100px;
            }
        }

        .dl1::before {
            content: "1. Lesung: ";
        }
        .dl2::before {
            content: "2. Lesung: ";
        }
        .dap:before {
            content: "Antwortpsalm: ";
        }
        .dev:before {
            content: "Evangelium: ";
        }
    }
}
