/* fira-sans-300 - latin_latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Fira Sans Light'), local('FiraSans-Light'),
       url('../fonts/fira-sans-v8-latin_latin-ext-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-v8-latin_latin-ext-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-300italic - latin_latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Fira Sans Light Italic'), local('FiraSans-LightItalic'),
       url('../fonts/fira-sans-v8-latin_latin-ext-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-v8-latin_latin-ext-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-regular - latin_latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Fira Sans Regular'), local('FiraSans-Regular'),
       url('../fonts/fira-sans-v8-latin_latin-ext-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-v8-latin_latin-ext-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-italic - latin_latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Fira Sans Italic'), local('FiraSans-Italic'),
       url('../fonts/fira-sans-v8-latin_latin-ext-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-v8-latin_latin-ext-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-700 - latin_latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Fira Sans Bold'), local('FiraSans-Bold'),
       url('../fonts/fira-sans-v8-latin_latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-v8-latin_latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-700italic - latin_latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Fira Sans Bold Italic'), local('FiraSans-BoldItalic'),
       url('../fonts/fira-sans-v8-latin_latin-ext-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-v8-latin_latin-ext-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}