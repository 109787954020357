/**********
 * Theme file: all-variables.scss
 *
 * ALL Bistuemers - Common CSS classes.
 */

.ap-header {

    // church logo coloring
    .bis-logo .ap-image-section {
        display: block;
        border-radius: 0;
        max-width: 400px;

        .image-src-box {
            background-color: $main-theme;
        }
    }

    .head.isfixed {
        .bis-logo .ap-image-section {
            max-width: 200px;
        }
    }

    .nav-sidelogo {
        > .sidelogo {
            background-color: $main-theme;
        }
    }

    @media (max-width: $screen-mobile-max) {
        .bis-logogroup {
            .bis-logo {
                // make sure there is space for the nav toggle on the right
                max-width: calc(100% - ( #{$nav-toggle-size} + 20px) );
            }
        }
    }
}



