/**********
 * Source file: mercury-compatibility.scss
 */

.ap-detail-page {

    .visual {
        margin-bottom: $default-margin;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        .subtitle {
            margin-top: $default-margin-small;
            line-height: 1.15;
            font-style: italic;
        }
    }
}
