
$calendar-event-color:          $grey-light !default;
$calendar-holiday-color:        $main-theme !default;
$calendar-maybeholiday-color:   transparentize($calendar-holiday-color, 0.5) !default;
$calendar-today-color:          $main-theme !default;



.ap-calendarview {

    #calendarviewload {
    	text-align: center;
    }

    .fc-h-event,
    .fc-event {
	    background-color: $calendar-event-color;
	    border: $calendar-event-color;
	    color: get-text-color($calendar-event-color);
	    padding: 5px;
	}

	.cal-holiday {
		background-color: $calendar-holiday-color;
		border: $calendar-holiday-color;
		color: get-text-color($calendar-holiday-color);
        padding: 5px;
	}

	.cal-maybeholiday {
	    background-color: $calendar-maybeholiday-color;
	    border: $calendar-maybeholiday-color;
	    color: get-text-color($calendar-maybeholiday-color);
        padding: 5px;
	}

	.fc-today {
	    background-color: $calendar-today-color;
	    color: get-text-color($calendar-today-color);
	}

	/* Optional rules for the different calendar entry types */
	.cal-a-blog {}

	.cal-a-event {}

	.cal-a-service {}
}